import common from '@/api/component/common';
import store from '@/store';
import helper from '@/api/helper';
import chartOptions from '../chart/chart_options';
import request from '../utils';

const getCombinerBoxDataEmpty = () => new Promise((resolve) => {
  const option = chartOptions.line_option_empty();
  resolve(option);
});

const getCombinerBoxData = (_rawData, measureList) => new Promise((resolve) => {
  let option;
  const seriesList = [];
  const date = [];
  const measure_List = {};
  const measure_Lists = [];
  measureList.forEach((item) => {
    if (item.value.indexOf('MPPT') > -1 && item.DC_Voltage !== undefined) {
      item.text = `${item.value}[${item.DC_Voltage}]`;
    }

    measure_List[item.value] = item;
    measure_Lists.push(item.value);
  });

  let i = 0;
  if (_rawData.data.success !== 'true') {
    option = chartOptions._line_opt_(date, measure_Lists, seriesList, measure_List);
    resolve(option);
    return;
  }

  measureList.forEach((item) => {
    const data = [];
    Object.keys(_rawData.data.data).forEach((key) => {
      if (i < 1) {
        date.push(_rawData.data.data[key].date);
      }
      const val = helper.toFixed(_rawData.data.data[key][item.value], 3);
      data.push(val);
    });
    i++;

    seriesList.push({
      name: item.value,
      type: 'line',
      data,
    });

    option = chartOptions._line_opt_(date, measure_Lists, seriesList, measure_List);
  });
  resolve(option);
});

const getCombinerBoxData_Dccurrent = (formdata, measureList) => new Promise((resolve) => {
  let option;
  const url = '/api/stringbox/list';
  const tempData = request.Api(url, formdata, 'post');

  tempData.then((_rawData) => {
    const seriesList = [];
    const date = [];
    let i = 0;
    measureList.forEach((item) => {
      const data = [];
      Object.keys(_rawData.data.data).forEach((key) => {
        if (i < 1) {
          date.push(_rawData.data.data[key].date);
        }
        data.push(_rawData.data.data[key][item.value]);
      });
      i++;

      seriesList.push({
        name: item.text,
        type: 'line',
        stack: 'x',
        data,
      });

      option = chartOptions.line_opt_right(date, measureList, seriesList);
    });

    resolve(option);
  });
});

const _getSantralComparisonMinuteChart = (formdata, measure, text, chartTypeModel,
  reportTypeModel, santralModel, stepTime) => new Promise((resolve) => {
  const temp_Data = request.Api(formdata.url, formdata.params, 'post');
  temp_Data.then((_rawData) => {
    const sortType = common.setSortType(reportTypeModel);

    const res = _rawData.data.data.sort((a, b) => {
      if (a[sortType] < b[sortType]) return -1;
      if (a[sortType] > b[sortType]) return 1;

      return 0;
    });

    const desserts = [];
    const dessert = {};
    const dateList = [];
    const yAxis = [];
    const series = [];
    const result = {};

    const combinerBox = formdata.params.condiniton.STRINGBOX;

    combinerBox.forEach((str) => {
      const tempData = [];
      Object.keys(res).forEach((item) => {
        let tempVal = 0;
        const obj = {};
        if (res[item].STRINGBOX == str) {
          const dd = common.dateListFonks(res[item], reportTypeModel);
          obj.date = dd;
          if (dateList.indexOf(dd) < 0) {
            dateList.push(dd);
          }
          tempVal = res[item][formdata.params.condiniton.measures].toFixed(3);

          tempData.push(tempVal);
        }

        obj[str] = tempVal;
        dessert[obj.date] = {
          ...dessert[obj.date],
          ...obj,
        };
      });

      const obj = {
        name: `${str} ${formdata.params.condiniton.measures}`,
        data: tempData,
        type: chartTypeModel,
      };

      yAxis.push({
        type: 'value',
        name: formdata.params.condiniton.measures,
      });

      series.push(obj);
    });
    Object.keys(dessert).forEach((key) => {
      desserts.push(dessert[key]);
    });

    const options = chartOptions.line_options_multies_1(text, combinerBox, dateList,
      series, common.colors(), yAxis);

    result.options = options;
    result.desserts = desserts;

    resolve(result);
  });
});

const getSantralComparisonMinuteChart = (formdata, measure, text, chartTypeModel,
  reportTypeModel, santralModel, combinerBox, stepTime) => new Promise((resolve) => {
  const temp_Data = request.Api(formdata.url, formdata.params, 'post');
  temp_Data.then((_rawData) => {
    const sortType = common.setSortType(reportTypeModel);

    const res = _rawData.data.data.sort((a, b) => {
      if (a[sortType] < b[sortType]) return -1;
      if (a[sortType] > b[sortType]) return 1;
      return 0;
    });

    const tempTimeData = {};
    res.forEach((item) => {
      let hh;
      let mm;
      const {
        STRINGBOX,
      } = item;
      item.Hour < 10 ? hh = `0${item.Hour}` : hh = `${item.Hour}`;
      item.MinuteGrup < 10 ? mm = `0${item.MinuteGrup}` : mm = `${item.MinuteGrup}`;
      const date = `${hh}:${mm}`;

      if (!tempTimeData[STRINGBOX]) {
        tempTimeData[STRINGBOX] = {};
      }

      tempTimeData[STRINGBOX][date] = item;
    });

    const desserts = [];
    const dessert = {};
    const dateList = [];
    const yAxis = [];
    const series = [];
    const result = {};
    const measure = formdata.params.condiniton.measures;

    const {
      measureList,
    } = store.getters;
    let selectMeasure;
    measureList.STRINGBOX.forEach((item) => {
      if (item.value === measure) {
        selectMeasure = item;
      } else {
        selectMeasure = {
          text: measure,
          value: measure,
          unit: 'V',
        };
      }
    });

    let index = 0;

    combinerBox.forEach((str) => {
      const minute = 0;
      const hour = 5;
      const tempData = [];

      for (let i = hour; i <= 20; i++) {
        for (let x = minute; x < 60; x += stepTime) {
          let h;
          let
            m;
          i < 10 ? h = `0${i}` : h = `${i}`;
          x < 10 ? m = `0${x}` : m = `${x}`;
          const time = `${h}:${m}`;
          console.log(time);
          const obj = {};
          obj.date = time;

          if (dateList.indexOf(time) < 0) {
            dateList.push(time);
          }

          let tempVal = 0;

          if (tempTimeData[str.value] !== undefined) {
            const tempValue = tempTimeData[str.value][time];

            if (tempValue !== undefined) {
              if (tempValue[selectMeasure.value] !== 0) {
                tempVal = tempValue[selectMeasure.value].toFixed(1);
              }
            }
          }

          tempData.push(tempVal);

          obj[str.value] = tempVal;
          dessert[time] = {
            ...dessert[time],
            ...obj,
          };
        }
      }

      const obj = {
        name: `${str.text} ${selectMeasure.text} ( ${selectMeasure.unit} )`,
        data: tempData,
        type: chartTypeModel,
      };

      yAxis.push({
        type: 'value',
        name: selectMeasure.value,
      });

      const yaxis = {
        type: 'value',
        name: `${selectMeasure.text} ( ${selectMeasure.unit} )`,
        show: false,
      };
      if (index > 0) {
        yaxis.show = false;
      }

      yAxis.push(yaxis);

      series.push(obj);
      index++;
    });
    Object.keys(dessert).forEach((key) => {
      desserts.push(dessert[key]);
    });

    const options = chartOptions.line_options_multies_1(text, combinerBox, dateList,
      series, common.colors(), yAxis);

    result.options = options;
    result.desserts = desserts;

    resolve(result);
  });
});

export default {
  getCombinerBoxData,
  getCombinerBoxDataEmpty,
  getCombinerBoxData_Dccurrent,
  getSantralComparisonMinuteChart,
};
