<template>
  <div>
    <v-container class="container--fluid">
      <v-row dense>
        <v-col
          v-if="componentsize"
          cols="12"
        >
          <v-card>
            <v-col
              cols="12"
              class="pl-0 pr-0 pb-1"
            >
              <v-row>
                <v-spacer />

                <v-col
                  cols="3"
                  sm="2"
                  md="2"
                  class="pr-3"
                >
                  <v-select
                    v-model="santral"
                    :items="santralDataList"
                    label="Santral"
                  />
                </v-col>

                <v-col
                  cols="3"
                  sm="2"
                  md="2"
                  class="pr-3"
                >
                  <v-select
                    v-model="combinerBox"
                    :items="combinerboxList[santral]"
                    label="Santral"
                  />
                </v-col>

                <v-col
                  cols="3"
                  sm="2"
                  md="2"
                  class="pr-3"
                >
                  <v-menu
                    ref="menu"
                    v-model="menu"
                    :close-on-content-click="false"
                    :return-value.sync="startDate"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{on, attrs}">
                      <v-text-field
                        v-model="startDate"
                        label="Tarih"
                        prepend-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                      />
                    </template>
                    <v-date-picker
                      v-model="startDate"
                      no-title
                      scrollable
                    >
                      <v-spacer />
                      <v-btn
                        text
                        color="primary"
                        @click="menu = false"
                      >
                        Cancel
                      </v-btn>
                      <v-btn
                        text
                        color="primary"
                        @click="$refs.menu.save(startDate)"
                      >
                        OK
                      </v-btn>
                    </v-date-picker>
                  </v-menu>
                </v-col>

                <v-col
                  cols="3"
                  sm="1"
                  md="1"
                  class="pr-3"
                >
                  <v-btn
                    color="primary"
                    @click="getSantralDailyDataChart"
                  >
                    Filtrele
                  </v-btn>
                </v-col>
              </v-row>
            </v-col>
            <v-col
              v-if="componentsize"
              cols="12"
            >
              <div
                v-if="dcCurrentData"
                class="pt-10"
              >
                <echart
                  id="dc_Current_Data_"
                  class-name="widget-content  pt-10"
                  height="450px"
                  width="100%"
                  :chart-data="dcCurrentData"
                />
              </div>
              <v-divider class="" />

              <div
                v-if="string_mpptDisplay == true"
                class="pt-2"
              >
                <echart
                  id="dc_string_mppt_"
                  class-name="widget-content  pt-10"
                  height="400px"
                  width="100%"
                  :chart-data="string_mpptData"
                />
              </div>
              <v-divider class="" />
              <div
                v-if="dcTotalData"
                class="pt-2"
              >
                <echart
                  id="dc_Total_Data_"
                  class-name="widget-content  pt-10"
                  height="400px"
                  width="100%"
                  :chart-data="dcTotalData"
                />
              </div>
              <v-divider class="" />

              <div
                v-if="dcVoltageDataDisplay == true"
                class="pt-10"
              >
                <echart
                  id="dc_Voltage_Data_"
                  class-name="widget-content pt-10"
                  height="450px"
                  width="100%"
                  :chart-data="dcVoltageData"
                />
              </div>
            </v-col>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import * as LineChartOption from '@/api/CombinerBox/ChartOption';
import Echart from '@/views/components/Echart/Chart.vue';
import request from '@/api/utils';
import moment from 'moment';
import helper from '@/api/helper';

export default {
  name: 'Dashboard',
  components: {
    Echart,
  },
  data: () => ({
    santral: '',
    combinerBox: '',
    startDate: moment().format('YYYY-MM-DD'),
    finishDate: moment().format('YYYY-MM-DD'),
    dailydata: {},
    monthlydata: {},
    yearlydata: {},
    year: moment().format('YYYY'),

    minutesDailyEnergydate: new Date().toISOString().substr(0, 10),
    menu: false,
    modal: false,
    menu2: false,
    chart_data_id: 'chartData1',
    dc_data_id: 'Dcvoltage1',
    chartData: {},
    Dcvoltage: {},
    componentsize: 1,
    measureList: [],
    measure_List: [],
    santralInfo: [],
    combinerboxList: [],
    dcCurrentMeasures: [],
    dcVoltageMeasures: [],
    dcCurrentData: [],
    dcVoltageData: [],
    dcTotalData: [],
    string_mpptMeasures: [],
    string_mpptData: [],
    string_mpptDisplay: [],
    dcVoltageDataDisplay: false,
  }),
  computed: {
    santralDataList() {
      const { santralDataList } = this.$store.getters;
      if (santralDataList === undefined) return;
      // eslint-disable-next-line consistent-return
      return santralDataList;
    },
  },
  watch: {
    santral() {
      const rest = this.santralInfo[this.santral];
      const combinerboxList = [];

      Object.keys(rest).forEach((key) => {
        combinerboxList.push({
          text: rest[key].STRINGBOXNAME,
          value: rest[key].STRINGBOX,
        });
      });

      this.combinerboxList[this.santral] = combinerboxList;
      this.combinerBox = combinerboxList[0].value;
    },

    combinerBox() {
      this.measure_List = [];
      this.dcVoltageData = [];
      this.dcVoltageDataDisplay = false;
      const rest = this.santralInfo[this.santral];
      this.setMeasures(rest[this.combinerBox]);
    },
  },
  created() {},
  mounted() {
    const myVar1 = setInterval(myTimer1, 1000);

    const self = this;
    let k = 0;
    function myTimer1() {
      if (k > 30) {
        clearInterval(myVar1);
      }

      k += 1;
      const { santral } = self.$store.getters.selectedSantralModel;
      const { santralStringboxData } = self.$store.getters;

      if (
        santral !== undefined
        && Object.keys(santralStringboxData).length > 0
      ) {
        self.santral = santral;
        self.santralInfo = santralStringboxData;
        clearInterval(myVar1);
      }
    }
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.resizeHandler);
  },
  methods: {
    setMeasures(rest) {
      this.componentsize++;
      const dcCurrentMeasures = [];
      const string_mppt = [];
      this.dcTotalData = [];
      this.dcCurrentData = [];

      const measure_List = rest.general_measure_list;

      const dc_vol_me_add = ['DCCurrent', 'TempBoard'];
      Object.keys(measure_List).forEach((k) => {
        if (
          measure_List[k].value.indexOf('DCCurrent') > -1
          && measure_List[k].value !== 'DCCurrent'
        ) {
          dcCurrentMeasures.push(measure_List[k]);
        }

        if (helper.isObjectKeysExist(rest, 'MPPT')) {
          if (measure_List[k].value.indexOf('MPPT') > -1) {
            measure_List[k].DC_Voltage = rest.MPPT[measure_List[k].value];
            string_mppt.push(measure_List[k]);
          }
        }
        if (dc_vol_me_add.indexOf(measure_List[k].value) > -1) {
          string_mppt.push(measure_List[k]);
        }
      });

      this.dcCurrentMeasures = dcCurrentMeasures;
      this.string_mpptMeasures = string_mppt;
      this.getSantralDailyDataChart();
    },

    getSantralDailyDataChart() {
      this.componentsize++;
      this.finishDate = moment(this.startDate)
        .add(1, 'day')
        .format('YYYY-MM-DD');

      const params = {
        condiniton: {
          startDate: this.startDate,
          finishDate: this.finishDate,
          santral: this.santral,
          stringbox: this.combinerBox,
          column: ['date'],
        },
      };
      const text = 'Daily trend of energies';

      const url = '/api/stringbox/list';
      const tmpdata = request.Api(url, params, 'post');
      tmpdata.then((data) => {
        if (this.dcCurrentMeasures.length > 0) {
          const tempData = LineChartOption.default.getCombinerBoxData(
            data,
            this.dcCurrentMeasures,
            text,
          );

          tempData.then((res) => {
            this.dcCurrentData = res;
          });
        }

        if (this.string_mpptMeasures.length > 0) {
          const tempData1 = LineChartOption.default.getCombinerBoxData(
            data,
            this.string_mpptMeasures,
            text,
          );

          tempData1.then((res) => {
            this.string_mpptData = res;
            this.string_mpptDisplay = true;
          });
        }

        if (this.dcVoltageMeasures.length > 0) {
          const tempData1 = LineChartOption.default.getCombinerBoxData(
            data,
            this.dcVoltageMeasures,
            text,
          );

          tempData1.then((res) => {
            this.dcVoltageData = res;
            this.dcVoltageDataDisplay = true;
          });
        }

        if (this.measure_List.length > 0) {
          const tempData1 = LineChartOption.default.getCombinerBoxData(
            data,
            this.measure_List,
            text,
          );

          tempData1.then((res) => {
            this.dcTotalData = res;
          });
        }
      });
    },

    forceRerender() {
      this.renderComponent = false;
      this.$nextTick().then(() => {
        this.renderComponent = true;
      });
    },
  },
};
</script>
